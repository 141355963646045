import React from 'react'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'

const ShopPage = () => (
  <>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <ProductGrid />
  </>
)

export default ShopPage
